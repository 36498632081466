<template>
  <router-view></router-view>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("dataStore");

export default {
  methods: {
    ...mapActions(["getUnreadNotificationsCount"]),
  },
  mounted() {
    this.getUnreadNotificationsCount();
  },
};
</script>
<style lang="scss">
.w-full {
  width: 100%;
}
header {
  margin: 31px 0px;
  &.top {
    margin-top: 0px;
  }
  h2 {
    color: #747373;
    font-size: 14px;
    font-weight: 500;
    line-height: 184%; /* 25.76px */
  }
}

.grid-prod {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.grid-user {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.grid-order {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #f2f2f2;
  background: #fff;
  overflow: auto;
}

.grid-notifications {
  background: #fff;
  padding: 27px;
  border-radius: 10px;
}

@media (max-width: 640px) {
  .grid-prod,
  .grid-user {
    gap: 8px;
    overflow-x: auto;
    padding: 30px 0px;
  }

  .grid-order {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .grid-notifications {
    margin-left: -17px;
    border-radius: 0px;
  }
}
</style>
