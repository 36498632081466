<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Manage Products." />
        <div class="loader">
          <Wave
            v-if="loading || updating || deleting"
            width="50px"
            color="#d9534f"
          />
        </div>
        <div>
          <md-button class="md-primary" :to="{ name: 'create-product' }">
            Add New Product
          </md-button>
        </div>
      </div>
      <md-table
        v-model="products.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-toolbar class="mb-4">
          <div class="md-toolbar-section-start">
            <md-button @click="exportExcel" class="md-primary button-icon">
              Download Excel
              <img src="@/assets/svg/excel.svg" />
            </md-button>
          </div>
          <div class="toolbar-filter-wrapper">
            <md-field md-clearable class="md-toolbar-section-end">
              <md-input placeholder="Search by name..." v-model="search" />
            </md-field>
            <md-menu md-size="medium" md-align-trigger>
              <md-button
                md-menu-trigger
                class="md-primary button-icon"
                disabled
              >
                Filter
                <img src="@/assets/svg/filter.svg" />
              </md-button>

              <md-menu-content>
                <md-menu-item>Approved</md-menu-item>
                <md-menu-item>Pending</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </md-table-toolbar>

        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          :class="{ 'table-highlight': item.promote_count > 0 }"
        >
          <md-table-cell md-label="S/N" md-numeric class="text-center">{{
            item.id
          }}</md-table-cell>
          <md-table-cell md-label="Title">
            <a :href="getProductUrl(item.slug)" target="_blank">{{
              item.title
            }}</a>
          </md-table-cell>
          <md-table-cell md-label="Price">{{
            item.price | formatMoney
          }}</md-table-cell>
          <md-table-cell md-label="Qty">{{ item.quantity }}</md-table-cell>
          <md-table-cell md-label="Category">
            <span> {{ item.category ? item.category.title : "--" }}</span>
          </md-table-cell>

          <md-table-cell md-label="Views">{{ item.visits }}</md-table-cell>

          <md-table-cell md-label="Created By">
            {{
              item.created_by
                ? item.created_by.first_name + " " + item.created_by.last_name
                : "--"
            }}</md-table-cell
          >

          <md-table-cell md-label="Updated By">
            {{
              item.created_by
                ? item.created_by.first_name + " " + item.created_by.last_name
                : "--"
            }}</md-table-cell
          >

          <md-table-cell md-label="Approval status">
            <div
              class="badge"
              :class="{
                success: item.approval_status == 'approved',
                danger: item.approval_status == 'disapproved',
              }"
            >
              {{ item.approval_status }}
            </div>
          </md-table-cell>
          <md-table-cell md-label="Created At">{{
            item.created_at | formatDate
          }}</md-table-cell>
          <md-table-cell md-label="Updated At">
            {{ item.updated_at | formatDate }}</md-table-cell
          >
          <md-table-cell md-label="Action">
            <md-menu md-size="medium" md-align-trigger>
              <md-button
                class="md-primary button-icon"
                md-menu-trigger
                :disabled="updating"
              >
                Menu
                <img src="@/assets/svg/caret.svg" />
              </md-button>

              <md-menu-content>
                <md-menu-item @click="setProduct(item)"
                  >Show Details</md-menu-item
                >
                <div>
                  <div v-if="hasPermission">
                    <md-menu-item
                      v-if="item.promote_count == 0"
                      @click="promote(item)"
                      >Promote <i class="material-icons">arrow_upward</i>
                    </md-menu-item>
                    <md-menu-item v-else @click="promote(item, 'update')"
                      >Update Promotion
                    </md-menu-item>

                    <md-menu-item
                      v-if="
                        ['disapproved', 'pending'].includes(
                          item.approval_status
                        )
                      "
                      @click="updateProductStatus(item.id, 'approved')"
                      >Approve</md-menu-item
                    >
                    <md-menu-item
                      v-if="
                        ['approved', 'pending'].includes(item.approval_status)
                      "
                      @click="updateProductStatus(item.id, 'disapproved')"
                      >Disapprove</md-menu-item
                    >
                    <md-menu-item @click="remove(item.id)">Delete</md-menu-item>
                  </div>
                  <router-link
                    tag="md-menu-item"
                    :to="{ name: 'edit-product', params: { id: item.id } }"
                    v-if="hasEditPermission"
                  >
                    Edit
                  </router-link>
                </div>
              </md-menu-content>
            </md-menu>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="products.mdPage"
        :records="products.mdCount"
        :per-page="products.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>

    <md-dialog :md-active.sync="show">
      <md-dialog-title> Product Details </md-dialog-title>
      <div class="cancel-modal">
        <i class="material-icons" @click="show = false">close</i>
      </div>

      <div class="modal-contain" v-if="product">
        <div class="d-section" style="margin-top: 10px">
          <div class="item">
            <strong>Product Name:</strong>
            <span> {{ product.title }}</span>
          </div>
          <div style="margin-left: 10px">
            <strong>Product Picture:</strong>
            <div class="p-media">
              <div class="display" v-for="image in product.images" :key="image">
                <img
                  :src="image"
                  onerror="this.src='https://via.placeholder.com/150x150?text=No%20Image'"
                  alt="Display Image"
                />
              </div>
            </div>
          </div>
          <div class="item">
            <strong>Description:</strong>
            <span v-html="product.description"> </span>
          </div>
          <div class="item">
            <strong>Category:</strong>
            <span>
              {{ product.category ? product.category.title : "NIL" }}</span
            >
          </div>
        </div>
        <div class="d-section">
          <div class="header">
            <h4>Product Features</h4>
          </div>

          <div
            v-for="attribute in product.attributes"
            :key="attribute.id"
            class="item"
            style="margin-left: 5px"
          >
            <strong>{{ attribute.title }}:</strong>
            <span> {{ attribute.pivot.value }}</span>
          </div>

          <div class="item">
            <strong>Does your product have a brand name:</strong>
            <span> {{ brand || "NIL" }}</span>
          </div>

          <div class="item">
            <strong>Price:</strong>
            <span> {{ product.price | formatMoney }}</span>
          </div>

          <div class="item">
            <strong>Discount:</strong>
            <span> {{ product.discount }}</span>
          </div>

          <div class="item">
            <strong>Price Measurement:</strong>
            <span> NIL </span>
          </div>

          <div class="item">
            <strong>Delivery Location:</strong>
            <span> NIL </span>
          </div>

          <div class="item">
            <strong>Product Quantity:</strong>
            <span> {{ product.quantity }}</span>
          </div>
        </div>

        <div class="d-section">
          <div class="header">
            <h4>Vendor Details</h4>
          </div>

          <div class="item">
            <strong>Name:</strong>
            <span> {{ product.created_by.name }}</span>
          </div>

          <div class="item">
            <strong>Phone number:</strong>
            <a
              :href="`tel:${
                product.created_by.phone_ext + product.created_by.phone
              }`"
            >
              {{ product.created_by.phone_ext + product.created_by.phone }}</a
            >
          </div>

          <div class="item">
            <strong>Email Address:</strong>
            <span> {{ product.created_by.email }}</span>
          </div>

          <div class="item">
            <strong>Vendor State:</strong>
            <span> {{ product.created_by?.vendor?.state?.title || "--" }}</span>
          </div>

          <div class="item">
            <strong>Vendor Address:</strong>
            <span> {{ product.created_by?.vendor?.address || "--" }}</span>
          </div>
        </div>

        <!-- <hr />
        <div v-if="product.created_by">
          <div>
            <h4>Owner's information</h4>
          </div>
          <div class="item">
            <strong>Fullname</strong>
            <span> {{ product.created_by.name }}</span>
          </div>

          <div class="item">
            <strong>Email Address</strong>
            <span> {{ product.created_by.email }}</span>
          </div>

          <div class="item">
            <strong>Phone Number</strong>
            <a
              :href="`tel:${
                product.created_by.phone_ext + product.created_by.phone
              }`"
            >
              {{ product.created_by.phone_ext + product.created_by.phone }}</a
            >
          </div>
        </div> -->
      </div>
    </md-dialog>
  </div>
</template>

<script>
// update 1
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("product");

export default {
  name: "Products",
  data: () => ({
    search: null,
    searched: [],
    paginatedProducts: [],
    product: null,
    show: false,
    updating: false,
    deleting: false,
    time: null,
  }),
  watch: {
    search(val) {
      this.waitForIt(val);
    },
  },
  methods: {
    ...mapActions(["getProducts", "updateStatus", "getOrder", "deleteProduct"]),
    searchOnTable() {
      this.searched = searchByName(this.users, this.search);
    },
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getProducts(data);
    },
    async submitSearch() {
      await this.getProducts({ keyword: this.search });
    },
    async updateProductStatus(id, status) {
      try {
        this.updating = true;
        await this.updateStatus({ approval_status: status, id });
        this.updating = false;
        this.toast("success", "Product Status Updated ");
      } catch (err) {
        this.updating = false;
        console.error(err);
      }
    },
    setProduct(product) {
      this.show = true;
      this.product = product;
    },
    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
    async exportExcel() {
      await this.$store.dispatch("auth/export", { model: "product" });
    },
    async promote(item, mode = "create") {
      this.$eventBus.$emit("promote", {
        item_type: this.promotions.PRODUCT,
        item_id: item.id,
        title: item.title,
        promote: item.promote,
        mode,
      });
    },
    getProductUrl(slug) {
      return `${process.env.VUE_APP_FRONTEND}products/${slug}`;
    },
    async remove(id) {
      let conf = confirm("Are you sure?");
      if (conf) {
        this.deleting = true;
        await this.deleteProduct(id);
        this.toast("success", "Product Deleted");
        this.deleting = false;
      }
    },
  },
  created() {
    this.searched = this.users;
    this.$eventBus.$on("promoted", (id) => {
      this.getProducts();
    });
  },
  mounted() {
    this.getProducts();
    // this.getOrder();
  },
  computed: {
    ...mapGetters(["products", "loading", "orders"]),
    hasPermission() {
      return this.$store.getters["auth/has_module_permission"]("crud");
    },
    hasEditPermission() {
      return this.$store.getters["auth/has_module_permission"](
        "update_shop_products"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
// .md-field {
//   max-width: 500px;
// }
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}
</style>
