<template>
  <div class="content dash-con">
    <div class="md-layout" v-if="hasPermission">
      <br />

      <!-- Product Sales Report-->
      <div class="d-flex" style="gap: 20px; width: 100%">
        <ProductReport :data="productData" :loading="p_loading" />
        <NewOrderProduct :data="registrations.users" :loading="u_loading" />
      </div>
      <!-- Order Status report -->
      <!-- <OrderReport :data="orderData" :loading="o_loading" /> -->

      <!-- Professional report -->
      <!-- <ProfessionalReport :data="userData" :loading="u_loading" /> -->

      <!-- Handyman report -->
      <!-- <HandymanReport :data="userData" :loading="u_loading" /> -->

      <!-- User Status report -->
      <UserReport :data="userData" :loading="u_loading" />

      <div class="d-flex w-full" style="gap: 20px">
        <div class="tab-con">
          <md-tabs md-sync-route>
            <md-tab id="tab-user" md-label="Users" to="#users" exact>
              <div class="tab-content">
                <div class="d-flex" style="justify-content: space-between">
                  <h2>Newly registered users</h2>
                  <div>
                    <md-button class="md-primary" to="/user-management"
                      >View All</md-button
                    >
                  </div>
                </div>

                <div style="margin-top: 20px">
                  <md-table v-model="registrations.users">
                    <md-table-row
                      slot="md-table-row"
                      slot-scope="{ item, index }"
                    >
                      <md-table-cell md-label="S/N">
                        {{ index + 1 }}
                      </md-table-cell>
                      <md-table-cell v-if="item.phone" md-label="Phone number"
                        >{{ item.phone_ext }}{{ item.phone }}</md-table-cell
                      >
                      <md-table-cell v-else md-label="Phone number"
                        >--</md-table-cell
                      >
                      <md-table-cell md-label="Full name">{{
                        item.first_name + " " + item.last_name
                      }}</md-table-cell>
                      <md-table-cell md-label="Email">{{
                        item.email
                      }}</md-table-cell>
                    </md-table-row>
                  </md-table>
                </div>
              </div>
            </md-tab>

            <md-tab id="tab-vendor" md-label="Vendors" to="#/vendors">
              <div class="tab-content">
                <div class="d-flex" style="justify-content: space-between">
                  <h2>Newly registered vendors</h2>
                  <div>
                    <md-button class="md-primary" to="/vendor-management"
                      >View All</md-button
                    >
                  </div>
                </div>

                <div style="margin-top: 20px">
                  <md-table v-model="registrations.vendors">
                    <md-table-row
                      slot="md-table-row"
                      slot-scope="{ item, index }"
                    >
                      <md-table-cell md-label="S/N">
                        {{ index + 1 }}
                      </md-table-cell>
                      <md-table-cell
                        v-if="item.vendor_phone"
                        md-label="Phone number"
                        >{{ item.vendor_phone_ext
                        }}{{ item.vendor_phone }}</md-table-cell
                      >
                      <md-table-cell
                        v-if="item.whatsapp_phone_no"
                        md-label="Whatsapp Number"
                      >
                        {{ item.whatsapp_phone_no }}</md-table-cell
                      >
                      <md-table-cell v-else md-label="Whatsapp Number"
                        >--</md-table-cell
                      >
                      <md-table-cell md-label="Store Name">{{
                        item.shop_name
                      }}</md-table-cell>
                    </md-table-row>
                  </md-table>
                </div>
              </div>
            </md-tab>

            <md-tab
              id="tab-professionals"
              md-label="Professionals"
              to="#/professionals"
            >
              <div class="tab-content">
                <div class="d-flex" style="justify-content: space-between">
                  <h2>Newly registered professionals</h2>
                  <div>
                    <md-button class="md-primary" to="/professional-management"
                      >View All</md-button
                    >
                  </div>
                </div>

                <div style="margin-top: 20px">
                  <md-table v-model="registrations.professional">
                    <md-table-row
                      slot="md-table-row"
                      slot-scope="{ item, index }"
                    >
                      <md-table-cell md-label="S/N">
                        {{ index + 1 }}
                      </md-table-cell>
                      <md-table-cell v-if="item.phone" md-label="Phone number"
                        >{{ item.phone_ext }}{{ item.phone }}</md-table-cell
                      >
                      <md-table-cell
                        v-if="item.whatsapp_phone_no"
                        md-label="Whatsapp Number"
                      >
                        {{ item.whatsapp_phone_no }}</md-table-cell
                      >
                      <md-table-cell v-else md-label="Whatsapp Number"
                        >--</md-table-cell
                      >
                      <md-table-cell md-label="Company Name">{{
                        item.company_name
                      }}</md-table-cell>
                    </md-table-row>
                  </md-table>
                </div>
              </div>
            </md-tab>

            <md-tab id="tab-handyman" md-label="Handyman" to="#/handyman">
              <div class="tab-content">
                <div class="d-flex" style="justify-content: space-between">
                  <h2>Newly registered handymen</h2>
                  <div>
                    <md-button class="md-primary" to="/handyman-management"
                      >View All</md-button
                    >
                  </div>
                </div>

                <div style="margin-top: 20px">
                  <md-table v-model="registrations.handyman">
                    <md-table-row
                      slot="md-table-row"
                      slot-scope="{ item, index }"
                    >
                      <md-table-cell md-label="S/N">
                        {{ index + 1 }}
                      </md-table-cell>
                      <md-table-cell v-if="item.phone" md-label="Phone number"
                        >{{ item.phone_ext }}{{ item.phone }}</md-table-cell
                      >
                      <md-table-cell
                        v-if="item.whatsapp_phone_no"
                        md-label="Whatsapp Number"
                      >
                        {{ item.whatsapp_phone_no }}</md-table-cell
                      >
                      <md-table-cell v-else md-label="Whatsapp Number"
                        >--</md-table-cell
                      >
                      <md-table-cell md-label="Company Name">{{
                        item.company_name
                      }}</md-table-cell>
                    </md-table-row>
                  </md-table>
                </div>
              </div>
            </md-tab>

            <md-tab id="tab-realtors" md-label="Realtors" to="#/realtors">
              <div class="tab-content">
                <div class="d-flex" style="justify-content: space-between">
                  <h2>Newly registered realtors</h2>
                  <div>
                    <md-button class="md-primary" to="/realtor-management"
                      >View All</md-button
                    >
                  </div>
                </div>

                <div style="margin-top: 20px">
                  <md-table v-model="registrations.realtors">
                    <md-table-row
                      slot="md-table-row"
                      slot-scope="{ item, index }"
                    >
                      <md-table-cell md-label="S/N">
                        {{ index + 1 }}
                      </md-table-cell>
                      <md-table-cell
                        v-if="item.realtor_phone"
                        md-label="Phone number"
                        >{{ item.realtor_phone_ext
                        }}{{ item.realtor_phone }}</md-table-cell
                      >
                      <md-table-cell
                        v-if="item.whatsapp_phone_no"
                        md-label="Whatsapp Number"
                      >
                        {{ item.whatsapp_phone_no }}</md-table-cell
                      >
                      <md-table-cell v-else md-label="Whatsapp Number"
                        >--</md-table-cell
                      >
                      <md-table-cell md-label="Company Name">{{
                        item.shop_name
                      }}</md-table-cell>
                    </md-table-row>
                  </md-table>
                </div>
              </div>
            </md-tab>
          </md-tabs>
        </div>
        <!-- Notifications report -->

        <Notifications :data="notifications" :loading="n_loading" />
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapActions, mapGetters } = createNamespacedHelpers("dataStore");

import {
  OrderReport,
  ProductReport,
  UserReport,
  Notifications,
  ProfessionalReport,
  HandymanReport,
  NewOrderProduct,
} from "@/components";

export default {
  components: {
    // OrderReport,
    ProductReport,
    UserReport,
    Notifications,
    // ProfessionalReport,
    // HandymanReport,
    NewOrderProduct,
  },
  data() {
    return {
      dailySalesChart: {
        data: {
          labels: ["M", "T", "W", "T", "F", "S", "S"],
          series: [[12, 17, 7, 17, 23, 18, 38]],
        },
        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      dataCompletedTasksChart: {
        data: {
          labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
          series: [[230, 750, 450, 300, 280, 240, 200, 190]],
        },

        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      emailsSubscriptionChart: {
        data: {
          labels: [
            "Ja",
            "Fe",
            "Ma",
            "Ap",
            "Mai",
            "Ju",
            "Jul",
            "Au",
            "Se",
            "Oc",
            "No",
            "De",
          ],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
          ],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
    };
  },
  methods: {
    ...mapActions([
      "getProductData",
      "getOrderData",
      "getUserData",
      "getNotifications",
      "getRegistrations",
      "getOrders",
    ]),
  },
  mounted() {
    this.getProductData();
    this.getOrderData();
    this.getUserData();
    this.getNotifications(5);
    this.getRegistrations();
    this.getOrders();
  },
  computed: {
    ...mapGetters([
      "loading",
      "p_loading",
      "o_loading",
      "u_loading",
      "n_loading",
      "u_loading",
      "productData",
      "orderData",
      "userData",
      "notifications",
      "registrations",
    ]),
    hasPermission() {
      return this.$store.getters["auth/has_module_permission"]("all");
    },
  },
};
</script>

<style>
.md-tabs-navigation {
  background-color: #f72121 !important;
  box-shadow: none !important;
  padding: 10px;
}
.dash-con {
  padding: 30px 30px !important;
}

@media (max-width: 640px) {
  .dash-con {
    padding: 30px 0px 30px 17px !important;
  }
}

.md-tabs-content table thead {
  display: table-header-group !important;
}
</style>

<style scoped lang="scss">
.tab-con {
  margin-top: 120px;
  width: 640px;

  .tab-content {
    padding: 0px 24px;
    h2 {
      font-size: 24px;
      font-family: 700;
    }
  }
}
</style>
