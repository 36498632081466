<template>
  <div class="">
    <header class="top">
      <h2>Product sales report</h2>
    </header>
    <div class="grid-prod">
      <stats-card-new
        :title="loading ? '--' : data.total_sale_product | formatMoney"
        :subtitle="loading ? '--' : data.total_month_sale_product | formatMoney"
        title_text="Total sales"
        subtitle_text="This Month"
      >
        <template slot="icon">
          <WalletIcon />
        </template>
      </stats-card-new>

      <stats-card-new
        :title="loading ? '--' : data.total_order | formatNumber"
        :subtitle="loading ? '--' : data.total_month_order | formatNumber"
        title_text="Total orders"
        subtitle_text="This month"
        link_label="View orders"
        link="/product/orders"
      >
        <template slot="icon">
          <BasketIcon />
        </template>
      </stats-card-new>

      <stats-card-new
        :title="
          loading ? '--' : parseInt(data.total_product_sold) | formatNumber
        "
        :subtitle="
          loading
            ? '--'
            : parseInt(data.total_month_product_sold) | formatNumber
        "
        title_text="Total product sold"
        subtitle_text="This month"
      >
        <template slot="icon">
          <CartIcon />
        </template>
      </stats-card-new>

      <stats-card-new
        :title="
          loading ? '--' : parseInt(data.total_product_view) | formatNumber
        "
        :subtitle="
          loading
            ? '--'
            : parseInt(data.total_month_product_view) | formatNumber
        "
        title_text="Total product views"
        subtitle_text="This Month"
      >
        <template slot="icon">
          <EyeIcon />
        </template>
      </stats-card-new>
    </div>
  </div>
</template>

<script>
import {
  StatsCardNew,
  WalletIcon,
  CartIcon,
  BasketIcon,
  EyeIcon,
} from "@/components";

export default {
  props: ["data", "loading"],
  components: {
    StatsCardNew,
    WalletIcon,
    CartIcon,
    BasketIcon,
    EyeIcon,
  },
};
</script>

<style></style>
