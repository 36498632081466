<template>
  <div class="w-full">
    <header class="top">
      <h2>User registrations report</h2>
    </header>
    <div class="grid-user">
      <stats-card-new
        :title="loading ? '--' : parseInt(data.total_buyers) | formatNumber"
        :subtitle="
          loading ? '--' : parseInt(data.total_month_buyers) | formatNumber
        "
        title_text="Total No. of Buyers"
        subtitle_text="This month registration"
        link_label="View buyers"
        link="/product/orders"
      >
        <template slot="icon">
          <CartIcon2 />
        </template>
      </stats-card-new>

      <stats-card-new
        :title="
          loading ? '--' : parseInt(data.total_professionals) | formatNumber
        "
        :subtitle="
          loading
            ? '--'
            : parseInt(data.total_month_professionals) | formatNumber
        "
        title_text="Total No. of Professionals"
        subtitle_text="This month registration"
        link_label="View professionals"
        link="/product/orders"
      >
        <template slot="icon">
          <HardHat />
        </template>
      </stats-card-new>

      <stats-card-new
        :title="loading ? '--' : parseInt(data.total_handymen) | formatNumber"
        :subtitle="
          loading ? '--' : parseInt(data.total_month_handymen) | formatNumber
        "
        title_text="Total No. of Handyman"
        subtitle_text="This month registration"
        link_label="View handyman"
        link="/product/orders"
      >
        <template slot="icon">
          <VectorIcon />
        </template>
      </stats-card-new>

      <stats-card-new
        :title="loading ? '--' : parseInt(data.total_vendors) | formatNumber"
        :subtitle="
          loading ? '--' : parseInt(data.total_month_vendors) | formatNumber
        "
        title_text="Total No. of Vendors"
        subtitle_text="This month registration"
        link_label="View vendors"
        link="/product/orders"
      >
        <template slot="icon">
          <BasketIcon2 />
        </template>
      </stats-card-new>

      <stats-card-new
        :title="loading ? '--' : parseInt(data.total_realtors) | formatNumber"
        :subtitle="
          loading ? '--' : parseInt(data.total_month_realtors) | formatNumber
        "
        title_text="Total No. of Realtors"
        subtitle_text="This month registration"
        link_label="View realtors"
        link="/product/orders"
      >
        <template slot="icon">
          <BuildingIcon />
        </template>
      </stats-card-new>
    </div>
  </div>
</template>

<script>
import {
  StatsCardNew,
  BasketIcon2,
  BuildingIcon,
  HardHat,
  VectorIcon,
  CartIcon2,
} from "@/components";

export default {
  props: ["data", "loading"],
  components: {
    StatsCardNew,
    BasketIcon2,
    BuildingIcon,
    HardHat,
    VectorIcon,
    CartIcon2,
  },
};
</script>

<style></style>
