<template>
  <div class="w-full">
    <header class="top d-flex justify-content-between">
      <h2>New Orders</h2>
      <md-button class="md-primary" to="/product/orders">View all</md-button>
    </header>
    <div class="order-con">
      <md-table v-model="orders">
        <md-table-row slot="md-table-row" slot-scope="{ item, index }">
          <md-table-cell md-label="S/N">
            {{ index + 1 }}
          </md-table-cell>
          <md-table-cell md-label="Full name"
            >{{ item.shipping_first_name
            }}{{ item.shipping_last_name }}</md-table-cell
          >
          <md-table-cell v-if="item.shipping_phone" md-label="Phone number">{{
            item.shipping_phone
          }}</md-table-cell>
          <md-table-cell v-else md-label="Phone number">--</md-table-cell>
          <md-table-cell md-label="Payment Status">{{
            item.payment_status
          }}</md-table-cell>
          <md-table-cell md-label="Price">{{
            item.total | formatMoney
          }}</md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("dataStore");

export default {
  props: ["data", "loading"],
  data: () => {
    return {
      res: this.data,
    };
  },
  components: {},
  computed: {
    ...mapGetters(["orders"]),
  },
};
</script>

<style scoped lang="scss">
.order-con {
  width: 100%;
  padding: 24px;
  border-radius: 20px;
  background: #ffffff;
  height: 368px;
}
</style>
